import React, {useState} from "react";
import Web3 from "web3";
import metamaskLogo from '../assets/logos/metamask.svg'

const hasWeb3 = () => {
    const {web3} = window;
    return typeof web3 !== 'undefined'
}


export const provider = () => window['ethereum']

export const withConnectedWeb3 = Component => props => {
    const [isConnected, setIsConnected] = useState(false)
    const [web3, setWeb3] = useState({})
    const [accounts, setAccounts] = useState({})

    const connect = async () => {
        const provider = window['ethereum']
        try {
            const web3 = new Web3(provider)
            const accounts = await web3.eth.requestAccounts()
            setWeb3(web3)
            setAccounts(accounts)
            setIsConnected(true)
        } catch {
            alert("rejected")
        }
    }

    if (!hasWeb3()) {
        return (
            <div className={'web3-warning'}>
                <p>
                    If you want to use this app, please install <a href="https://metamask.io/"
                                                                        rel="noopener noreferrer"
                                                                        target="_blank">metamask</a>.
                </p>
                <p>
                    May also work with other plugins/ browsers, but not tested.
                </p>
            </div>
        )
    }
    if(!isConnected){
        return (
            <div className={'connect-button'}>
                <button onClick={connect} className={''}>
                    <img src={metamaskLogo} alt={'metamask logo'} />
                    <span>
                        connect metamask
                    </span>
                </button>
            </div>
        )
    }
    return (
        <Component
            web3={web3}
            accounts={accounts}
            {...props}
        />
    )
}