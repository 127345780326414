import React from "react";
import './transaction-error.scss'
import ErrorIcon from "mdi-react/ErrorIcon";

const TransactionError = ({error}) => {
    return (
        <div className={'transaction-error'}>
            <ErrorIcon />
            <h2>
                transaction error
            </h2>
            <p className='error'>
                {error}
            </p>
        </div>
    )
}

export default TransactionError