import {useEffect, useRef} from "react";

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useMountEffect = effect => useEffect(effect, []);
// eslint-disable-next-line react-hooks/exhaustive-deps
export const useUnmountEffect = effect => useEffect(()=>effect, []);
// eslint-disable-next-line react-hooks/exhaustive-deps
export const useChillEffect = (effect, deps = []) => useEffect(()=>effect, deps);


export const useEffectNotOnMount = (action = () => {}, deps) => {
    const isMounted = useRef(false);
    useEffect(() => {
        if (isMounted.current) {
            action();
        } else {
            isMounted.current = true;
        }
        // eslint-disable-next-line
    }, [deps]);
};