import React, {useImperativeHandle, useRef, useState} from "react";
import {useMountEffect} from "../../hooks/mounting-hooks";
import {useDispatch, useSelector} from "react-redux";
import './modal.scss'
import {closeModal, getIsActive} from "../../ducks/modalDuck";
import CloseIcon from "mdi-react/CloseIcon";
import FocusLock from 'react-focus-lock';
import KeyDownCatcher from "../util/KeyDownCatcher";

export let modalRef = null

const Modal = () => {

    const ref = useRef()
    const dispatch = useDispatch()

    const isActive = useSelector(getIsActive)
    const close = () => dispatch(closeModal())

    useMountEffect(() => {
        modalRef = ref
    })


    return (
        <div className={`modal ${isActive ? 'active' : 'inactive'}`}>
            <div className='modal__overlay' onClick={close}/>
            <ModalContainer ref={ref}/>
        </div>
    )
}

const ModalContainer = React.forwardRef((props, inputRef) => {

    const dispatch = useDispatch()

    const [children, setChildren] = useState(props.children)

    const isActive = useSelector(getIsActive)
    const close = () => dispatch(closeModal())

    useImperativeHandle(inputRef, () => ({
        update: children => {
            setChildren(children)
        }
    }))

    return (
        <KeyDownCatcher disabled={!isActive} onEsc={close}>
            <FocusLock disabled={!isActive} autoFocus={false}>
                <div className='modal__container'>
                    <div className='modal__content' ref={inputRef}>
                        {children}
                    </div>
                    <button className='close-modal-button' onClick={close}>
                        <CloseIcon/>
                    </button>
                </div>
            </FocusLock>
        </KeyDownCatcher>
    )

})

export default Modal