import React, {useEffect} from "react";
import Particles2 from 'particlesjs';
import './particles.scss'

const Particles = () => {
    useEffect(()=>{
        Particles2.init({
            selector: '.background',
            color: '#ecf0f1',
            maxParticles: 200,
            sizeVariations: 10,
            speed: .25,
        });
    },[])
    return (
        <canvas className="background" />
    )
}

export default Particles