import React from 'react';

export const KEY_ENTER = 'Enter'
export const KEY_KODE_ENTER = 13
export const KEY_KODE_ESC = 27
export const KEY_KODE_SPACEBAR = 32
export const KEY_TAB = 'Tab'

export function isEnterPressed(event) {
    return (event.key === KEY_ENTER)
}

export function isEscPressed(event) {
    const keyCode = event.which || event.keyCode
    return (keyCode === KEY_KODE_ESC)
}

export function isTabPressed(event) {
    return (!event.shiftKey && event.key === KEY_TAB)
}

export function isShiftTabPressed(event) {
    return (event.shiftKey && event.key === KEY_TAB)
}

export function isSpacePressed(event) {
    const keyCode = event.which || event.keyCode
    return (keyCode === KEY_KODE_SPACEBAR)
}

const KeyDownCatcher = ({children, onClick, onEnter, onTab, onShiftTab, onEsc, onSpace, onAnyKey, preventDefaultOnTab, disabled = false, ...restOfProps}) => {

    const handleKeyPress = e => {

        if (disabled) {
            return
        }

        if (onEnter && isEnterPressed(e)) {
            onEnter()
            return
        }

        if (onShiftTab && isShiftTabPressed(e)) {
            preventDefaultOnTab && e.preventDefault()
            onShiftTab()
            return
        }

        if (onTab && isTabPressed(e)) {
            preventDefaultOnTab && e.preventDefault()
            onTab()
            return
        }

        if (onEsc && isEscPressed(e)) {
            onEsc()
            return
        }

        if (onSpace && isSpacePressed(e)) {
            onSpace()
            return
        }

        if (onAnyKey) {
            onAnyKey()
        }

    }

    return (
        <div
            onKeyDown={handleKeyPress}
            onClick={onClick}
            {...restOfProps}
        >
            {children}
        </div>
    )
}

export default KeyDownCatcher