import './App.scss';
import React from "react";
import Swap from "./Swap";
import HammerIcon from "mdi-react/HammerIcon";
import Particles from "./util/Particles";
import Modal from "./modal/Modal";
import IconGradients from "./util/IconGradients";
import ExternalLink from "./util/ExternalLink";

function App() {
    return (
        <div className="App">
            <Particles />
            <header className="App-header">
                <h1>
                    makerbreak.io
                </h1>
                <h2>
                    break your maker <HammerIcon />
                </h2>
            </header>
            <main>
                <Swap />
            </main>
            <footer>
                <div>
                    <ExternalLink href={`${process.env.REACT_APP_ETHERSCAN_HOST}/address/${process.env.REACT_APP_BREAKER_CONTRACT_ADR}`}>smart contract</ExternalLink> by <ExternalLink href={'https://twitter.com/brianmcmichael'}>@brianmcmichael</ExternalLink>
                </div>
                <div>
                    app by <ExternalLink href={'https://makerburn.com'}>makerburn.com</ExternalLink>
                </div>
            </footer>
            <Modal/>
            <IconGradients />
        </div>
    );
}

export default App;
