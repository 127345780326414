import React from "react";

const ExternalLink = ({href, className = '', children}) => {
    return (
        <a className={`external-link ${className}`} href={href} target={'_blank'} rel="noopener noreferrer">
            {children}
        </a>
    )
}

export default ExternalLink