import {numberFormat} from "./numbers";

export const MONTHS = [
  {
    id: 1,
    value: 'January',
  },
  {
    id: 2,
    value: 'February',
  },
  {
    id: 3,
    value: 'March',
  },
  {
    id: 4,
    value: 'April',
  },
  {
    id: 5,
    value: 'May',
  },
  {
    id: 6,
    value: 'June',
  },
  {
    id: 7,
    value: 'July',
  },
  {
    id: 8,
    value: 'August',
  },
  {
    id: 9,
    value: 'September',
  },
  {
    id: 10,
    value: 'October',
  },
  {
    id: 11,
    value: 'November',
  },
  {
    id: 12,
    value: 'December',
  },
]

export const monthNumberToName = n =>
    MONTHS.find(month => month.id === n)?.value

export const SEC = 1000;
export const MIN = SEC * 60;
export const HOUR = MIN * 60;
export const DAY = HOUR * 24;
export const WEEK = DAY * 7;

export const getNorwegianDateFormat = date => {
  if(!date)return null;
  let year = date.getFullYear();
  let month = date.getMonth()+1;
  let day = date.getDate();
  if(month < 10)month = "0"+month;
  if(day < 10)day = "0"+day;
  return `${day}.${month}.${year}`;
};

export const getNorwegianDateAndTimeFormat = date => {
  if(!date)return null;

  let year = date.getFullYear();
  let month = date.getMonth()+1;
  let day = date.getDate();

  if(month < 10)month = "0"+month;
  if(day < 10)day = "0"+day;

  let hour = date.getHours();
  let minute = date.getMinutes();

  if(hour < 10)hour = "0"+hour;
  if(minute < 10)minute = "0"+minute;

  return `${day}.${month}.${year} ${hour}:${minute}`;
};

export const getFormDate = isoDateString => {
  if(!isoDateString)return "";
  const date = parseIsoDate(isoDateString);
  let year = date.getFullYear();
  let month = date.getMonth()+1;
  let day = date.getDate();
  if(month < 10)month = "0"+month;
  if(day < 10)day = "0"+day;
  return year+'-'+month+'-'+day;
};

export function secondsToHours(seconds){
  var hours = Math.floor(seconds / 3600);
  var minutes = Math.floor((seconds - (hours * 3600)) / 60);
  var secs = seconds % 60;
  if(hours < 10)hours = "0"+hours;
  if(minutes < 10)minutes = "0"+minutes;
  if(secs < 10)secs = "0"+secs;
  return     hours + ':' +minutes+ ':' +secs;
}

export function byteFormatter(bytes){
  var KB = bytes / 1024;
  var MB = KB / 1024;
  var GB = MB / 1024;
  if(GB > 0.5){
    return numberFormat(GB, 2) + " GB"
  } else
  if(MB > 0.5){
    return numberFormat(MB, 2) + " MB"
  } else {
    return numberFormat(KB, 2) + " KB"
  }
}

export function pad(n) {
  return (n < 10) ? ("0" + n) : n;
}

export function parseDate(myDate){
  if(!myDate)return null;

  let parts, date, time, dt;

  parts = myDate.split(/[T ]/); // Split on `T` or a space to get date and time
  date = parts[0];
  time = parts[1];

  dt = new Date();

  parts = date.split(/[-/]/);  // Split date on - or /
  dt.setFullYear(parseInt(parts[0], 10));
  dt.setMonth(parseInt(parts[1], 10) - 1); // Months start at 0 in JS
  dt.setDate(parseInt(parts[2], 10));

  parts = time.split(/:/);    // Split time on :
  dt.setHours(parseInt(parts[0], 10));
  dt.setMinutes(parseInt(parts[1], 10));
  dt.setSeconds(parseInt(parts[2], 10));

  return dt;
}

export const parseIsoDate = date => date && new Date(fixISOStringForIE(date));

export const fixISOStringForIE = dateString => {
  const hasTime = dateString.includes('T');
  if (!hasTime) return dateString;

  //til "1984-02-25T00:00:00+00:00" format from "1984-02-25T00:00:00+0000"
  return `${dateString.substring(0, dateString.length - 2)}:${dateString.substring(dateString.length - 2, dateString.length)}`
};

