import React from "react";
import './icon-gradients.scss'

const iconGradients = [
    'hammer-gradient',
]

const IconGradients = () => {
    return (
        <svg
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            className="icon-gradients shape"
        >
            <defs>
                {iconGradients.map(gradient => (
                    <linearGradient key={gradient} id={gradient} x2="0.35" y2="1" gradientTransform="rotate(40)">
                        <stop offset="-50%" stopColor="var(--color-stop)"/>
                        <stop offset="110%" stopColor="var(--color-bot)"/>
                    </linearGradient>
                ))}
            </defs>
        </svg>
    )
}

export default IconGradients