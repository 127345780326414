import React from "react";
import CheckIcon from "mdi-react/CheckIcon";
import Transaction from "./Transaction";
import './transaction-success.scss'

const TransactionSuccess = ({hash}) => {
    return (
        <div className={'transaction-success'}>
            <CheckIcon />
            <h2>
                transaction success
            </h2>
            <Transaction hash={hash}/>
        </div>
    )
}

export default TransactionSuccess