import Web3 from "web3";

export const DECIMAL_FORMAT = {
    FLEX: -1,
    FLEX_DEC: -2,
    FLEX_INT: -3,
    FLEX_ONE_DEC_ONLY: -4,
}

export function numberFormat(number, decimals) {
    if ([DECIMAL_FORMAT.FLEX_INT, DECIMAL_FORMAT.FLEX].includes(decimals)) {
        let suffix = ""
        if (Math.abs(number) > 100_000_000_000) {
            suffix = "T"
            number = (Math.round(number / 10_000_000_000) / 100)
        } else
        if (Math.abs(number) > 100_000_000) {
            suffix = "B"
            number = (Math.round(number / 10_000_000) / 100)
        } else if (Math.abs(number) > 100_000) {
            suffix = "M"
            number = (Math.round(number / 10_000) / 100)
        } else if (Math.abs(number) > 1_000) {
            suffix = "K"
            number = (Math.round(number / 10) / 100)
        } else if (Math.abs(number) > 10) {
            number = Math.round(number)
        } else if(decimals === DECIMAL_FORMAT.FLEX_INT) {
            number = Math.round(number)
        }
        if(number % 1 === 0 || decimals === DECIMAL_FORMAT.FLEX_INT){
            decimals = 0
        } else if(number >= 10){
            decimals = 1
        } else {
            decimals = 2
        }
        return number_format(number, decimals, '.', ',') + suffix
    }
    if ([DECIMAL_FORMAT.FLEX_ONE_DEC_ONLY].includes(decimals)) {
        if (Math.abs(number) > 100_000_000) {
            number = (Math.round(number / 100_000_000) / 10) + 'B'
        } else if (Math.abs(number) >= 1_000_000) {
            number = (Math.round(number / 100_000) / 10) + 'M'
        } else if (Math.abs(number) >= 10_000) {
            number = (Math.round(number / 100) / 10) + 'K'
        } else if (Math.abs(number) >= 10) {
            number = Math.round(number)
        } else {
            number = Math.round(number * 10) / 10
        }
        return number
    }
    if(decimals === DECIMAL_FORMAT.FLEX_DEC){
        if(Math.abs(number) < 10){
            decimals = 4;
        } else
        if(Math.abs(number) < 1000){
            decimals = 2;
        } else {
            decimals = 0;
        }
    }
    return number_format(number, decimals, '.', ',')
}

export function number_format(number, decimals, decPoint, thousandsSep) {
    number = (number + '')
        .replace(/[^0-9+\-Ee.]/g, '')
    var n = !isFinite(+number) ? 0 : +number
    var prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    var sep = (typeof thousandsSep === 'undefined') ? ',' : thousandsSep
    var dec = (typeof decPoint === 'undefined') ? '.' : decPoint
    var s = ''
    var toFixedFix = function (n, prec) {
        var k = Math.pow(10, prec)
        return '' + (Math.round(n * k) / k)
            .toFixed(prec)
    }
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
        .split('.')
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || '')
        .length < prec) {
        s[1] = s[1] || ''
        s[1] += new Array(prec - s[1].length + 1)
            .join('0')
    }
    return s.join(dec)
}

export function round(number, decimalPoints){
  return Math.round(number * Math.pow(10, decimalPoints)) / Math.pow(10, decimalPoints)
}



function isString(s) {
    return (typeof s === 'string' || s instanceof String)
}

const BN = Web3.utils.BN

export function toBaseUnit(value, decimals) {
    if (!isString(value)) {
        throw new Error('Pass strings to prevent floating point precision issues.')
    }
    const ten = new BN(10);
    const base = ten.pow(new BN(decimals));

    // Is it negative?
    let negative = (value.substring(0, 1) === '-');
    if (negative) {
        value = value.substring(1);
    }

    if (value === '.') {
        throw new Error(
            `Invalid value ${value} cannot be converted to`
            + ` base unit with ${decimals} decimals.`);
    }

    // Split it into a whole and fractional part
    let comps = value.split('.');
    if (comps.length > 2) { throw new Error('Too many decimal points'); }

    let whole = comps[0], fraction = comps[1];

    if (!whole) { whole = '0'; }
    if (!fraction) { fraction = '0'; }
    if (fraction.length > decimals) {
        throw new Error('Too many decimal places');
    }

    while (fraction.length < decimals) {
        fraction += '0';
    }

    whole = new BN(whole);
    fraction = new BN(fraction);
    let wei = (whole.mul(base)).add(fraction);

    if (negative) {
        wei = wei.neg();
    }

    return new BN(wei.toString(10), 10);
}