import React from "react";

import './transaction.scss'
import ExternalLink from "../util/ExternalLink";
import ExternalLinkIcon from "mdi-react/ExternalLinkIcon";

const Transaction = ({ hash }) => {
    return (
        <div className={'transaction'}>
            <div className={'hash'}>
                {hash}
            </div>
            <div className={'link'}>
                <ExternalLink href={`${process.env.REACT_APP_ETHERSCAN_HOST}/tx/${hash}`}>
                    view on etherscan <ExternalLinkIcon />
                </ExternalLink>
            </div>
        </div>
    )
}

export default Transaction