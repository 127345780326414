import React, {useEffect, useState} from "react";
import {withConnectedWeb3} from "../hocs/web3Hoc";
import HammerIcon from "mdi-react/HammerIcon";
import BandageIcon from "mdi-react/BandageIcon";

import MakerABI from "../abi/maker.json"
import BreakerABI from "../abi/breaker.json"
import {getContract} from "../util/contract";
import {CONTRACT_ADDRESS} from "../util/adr";
import {toBaseUnit} from "../util/formatting/numbers";
import Counter from "./util/Counter";
import {useDispatch, useSelector} from "react-redux";
import {openModal} from "../ducks/modalDuck";
import PendingTransaction from "./transaction/PendingTransaction";
import {
    getError,
    getHash,
    getIsPosting,
    getIsSuccess,
    postTransaction,
    setError,
    transactionSuccess
} from "../ducks/transactionDuck";
import TransactionError from "./transaction/TransactionError";
import TransactionSuccess from "./transaction/TransactionSuccess";
import LockIcon from "mdi-react/LockIcon";
import LockOpenIcon from "mdi-react/LockOpenIcon";
import {useMountEffect} from "../hooks/mounting-hooks";
import SwapVerticalIcon from "mdi-react/SwapVerticalIcon";
import AlertOutlineIcon from "mdi-react/AlertOutlineIcon";

const MAKER_MAX_INPUT = 1_000_000
const BREAKER_MAX_INPUT = 1_000_000_000_000_000

const getMakerDetailsAPI = async (web3, accounts) => {
    const MakerContract = await getContract(web3, CONTRACT_ADDRESS.MAKER, MakerABI)
    const [account] = accounts
    const decimals = await MakerContract.methods.decimals().call()
    const balance = await MakerContract.methods.balanceOf(account).call()
    const allowance = await MakerContract.methods.allowance(account, CONTRACT_ADDRESS.BREAKER).call()
    return {
        decimals,
        balance,
        allowance,
    }
}

const getBreakerDetailsApi = async (web3, accounts, makerToBreak, breakerToMake) => {
    const BreakerContract = await getContract(web3, CONTRACT_ADDRESS.BREAKER, BreakerABI)
    const [account] = accounts
    const decimals = await BreakerContract.methods.decimals().call()
    const balance = await BreakerContract.methods.balanceOf(account).call()
    const mkrToBkr = await BreakerContract.methods.mkrToBkr(isNaN(makerToBreak) ? 0 : BigInt(makerToBreak)).call()
    const bkrToMkr = await BreakerContract.methods.bkrToMkr(isNaN(breakerToMake) ? 0 : BigInt(breakerToMake)).call()
    return {
        decimals,
        mkrToBkr,
        balance,
        bkrToMkr,
    }
}

const Swap = withConnectedWeb3(({web3, accounts}) => {

    const dispatch = useDispatch()

    const isPosting = useSelector(getIsPosting)
    const isSuccess = useSelector(getIsSuccess)
    const hash = useSelector(getHash)
    const error = useSelector(getError)

    const [isBreak, setIsBreak] = useState(true)

    const [mkrAmount, setMkrAmount] = useState(0)
    const [mkrDecimals, setMkrDecimals] = useState(18)
    const [mkrAllowance, setMkrAllowance] = useState(0)

    const [bkrAmount, setBkrAmount] = useState(0)
    const [bkrDecimals, setBkrDecimals] = useState(18)

    const [makerToBreakInput, setMakerToBreakInput] = useState(0)
    const [breakerToMakeInput, setBreakerToMakeInput] = useState(0)

    const [mkrToBkr, setMkrToBkr] = useState(0)
    const [bkrToMkr, setBkrToMkr] = useState(0)

    const isMkrUnlocked = mkrAllowance > 0
    const isWaiting = !!isPosting

    const isBkrAmountInsufficient = breakerToMakeInput * 10 ** bkrDecimals > bkrAmount && !isBreak
    const isMkrAmountInsufficient = makerToBreakInput * 10 ** mkrDecimals > mkrAmount && isBreak

    const isDisabled = isWaiting || isNaN(makerToBreakInput) || isNaN(breakerToMakeInput) || isBkrAmountInsufficient || isMkrAmountInsufficient

    const toggleDirection = () => {
        if(isBreak){
            setBreakerToMakeInput(mkrToBkr / 10 ** bkrDecimals)
        } else {
            setMakerToBreakInput(bkrToMkr / 10 ** mkrDecimals)
        }
        setIsBreak(!isBreak)
    }

    useMountEffect(() => {
        getMakerDetails(web3).catch().then(()=>{
            setMakerToBreakInput(0.001)
        })
    })

    useEffect(()=>{
        getMakerDetails(web3).catch()
        // eslint-disable-next-line
    },[makerToBreakInput, breakerToMakeInput])

    useEffect(()=>{
        makerToBreakInput > MAKER_MAX_INPUT && setMakerToBreakInput(MAKER_MAX_INPUT)
        makerToBreakInput < 0 && setMakerToBreakInput(0)
        // eslint-disable-next-line
    },[makerToBreakInput])

    useEffect(()=>{
        breakerToMakeInput > BREAKER_MAX_INPUT && setBreakerToMakeInput(BREAKER_MAX_INPUT)
        breakerToMakeInput < 0 && setBreakerToMakeInput(0)
        // eslint-disable-next-line
    },[breakerToMakeInput])

    useEffect(()=>{
        !!isPosting && dispatch(openModal(<PendingTransaction hash={hash} />))
        // eslint-disable-next-line
    },[isPosting, hash])

    useEffect(()=>{
        !!isSuccess && dispatch(openModal(<TransactionSuccess hash={hash}/>))
        // eslint-disable-next-line
    },[isSuccess, hash])

    useEffect(()=>{
        !!error && dispatch(openModal(<TransactionError error={error} />))
        // eslint-disable-next-line
    },[error])

    const getMakerDetails = async () => {
        const {balance, allowance, decimals} = await getMakerDetailsAPI(web3, accounts)
        await setMkrAmount(balance)
        await setMkrDecimals(decimals)
        await setMkrAllowance(allowance)
        // console.log("mkr balance", balance)
        // console.log("mkr allowance", allowance)
        await getBreakerDetails()
    }

    const getBreakerDetails = async () => {

        const makerToBreak = toBaseUnit(""+makerToBreakInput, mkrDecimals)
        const breakerToMake = toBaseUnit(""+breakerToMakeInput, bkrDecimals)

        const {balance, decimals, mkrToBkr, bkrToMkr} = await getBreakerDetailsApi(web3, accounts, makerToBreak, breakerToMake)

        // console.log("bkr balance", balance)
        // console.log("makerToBreakInput", makerToBreakInput)

        setBkrAmount(balance)
        setBkrDecimals(decimals)

        setMkrToBkr(mkrToBkr)
        setBkrToMkr(bkrToMkr)
    }

    const swapMkrToBkrTransaction = async () => {

        const makerToBreak = toBaseUnit(""+makerToBreakInput, mkrDecimals)
        const BreakerContract = await getContract(web3, CONTRACT_ADDRESS.BREAKER, BreakerABI)
        const [account] = accounts

        // console.log("makerToBreak", makerToBreak)

        dispatch(setError())

        BreakerContract.methods.breaker(makerToBreak).send({from: account})
            .on('transactionHash', (hash) => {
                // console.log(hash)
                dispatch(postTransaction(hash))
            })
            .on('confirmation', function (confirmationNumber, receipt) {
                // console.log("confirmation", confirmationNumber)
                // console.log("receipt", receipt)
            })
            .on('receipt', function (receipt) {
                const {transactionHash} = receipt
                dispatch(transactionSuccess(transactionHash))
                setMakerToBreakInput(0)
                getMakerDetails()
            })
            .on('error', function (error, receipt) {
                dispatch(setError(error?.message || error))
            })
    }

    const swapBkrToMkrTransaction = async () => {

        const breakerToMake = toBaseUnit(""+breakerToMakeInput, bkrDecimals)

        const BreakerContract = await getContract(web3, CONTRACT_ADDRESS.BREAKER, BreakerABI)
        const [account] = accounts

        dispatch(setError())

        BreakerContract.methods.maker(breakerToMake).send({from: account})
            .on('transactionHash', (hash) => {
                dispatch(postTransaction(hash))
            })
            .on('receipt', function (receipt) {
                const {transactionHash} = receipt
                dispatch(transactionSuccess(transactionHash))
                setBreakerToMakeInput(0)
                getMakerDetails()
            })
            .on('error', function (error, receipt) {
                dispatch(setError(error?.message || error))
            })
    }

    const unlockMkrTransaction = async () => {

        const makerToApprove = toBaseUnit(""+MAKER_MAX_INPUT, 18)

        const MakerContract = await getContract(web3, CONTRACT_ADDRESS.MAKER, MakerABI)
        const [account] = accounts

        dispatch(setError())

        MakerContract.methods.approve(CONTRACT_ADDRESS.BREAKER, makerToApprove).send({from: account})
            .on('transactionHash', (hash) => {
                dispatch(postTransaction(hash))
            })
            .on('receipt', (receipt) => {
                const {transactionHash} = receipt
                dispatch(transactionSuccess(transactionHash))
                getMakerDetails()
                setBreakerToMakeInput(0)
            })
            .on('error', (error, receipt) => {
                dispatch(setError(error?.message || error))
            })
    }

    return (
        <div>
            {isWaiting &&
                <div>
                    Waiting for transaction
                </div>
            }
            <div className={'your-account'}>
                <div>
                    <h4>
                       MKR balance
                    </h4>
                    <div className='amount'>
                        <Counter amount={mkrAmount / 10 ** mkrDecimals} decimals={4} timeout={25} noColor ignoreZeroLimit={true}/>{!isMkrUnlocked ? <LockIcon /> : <LockOpenIcon />}
                    </div>
                </div>
                <div>
                    <h4>
                        BKR balance
                    </h4>
                    <div className='amount'>
                        <Counter amount={bkrAmount / 10 ** bkrDecimals} decimals={0} timeout={25} noColor ignoreZeroLimit={true}/>
                    </div>
                </div>
            </div>
            {isBreak &&
                <div className='input'>
                    <div className='token'>
                        MKR
                    </div>
                    <input
                        type='text'
                        value={makerToBreakInput}
                        onChange={e => setMakerToBreakInput(e.target.value)}
                        max={1_000_000}
                    />
                    <div className='set-max'>
                        <button
                            onClick={()=>setMakerToBreakInput(mkrAmount / 10 ** mkrDecimals)}
                        >
                            max
                        </button>
                    </div>
                </div>
            }
            {!isBreak &&
            <div className='input'>
                <div className='token'>
                    BKR
                </div>
                <input
                    type='text'
                    value={breakerToMakeInput}
                    onChange={e => setBreakerToMakeInput(e.target.value)}
                />
                <div className='set-max'>
                    <button
                        onClick={()=>setBreakerToMakeInput(bkrAmount / 10 ** bkrDecimals)}
                    >
                        max
                    </button>
                </div>
            </div>
            }
            <div className='toggle-direction'>
                <button onClick={toggleDirection}>
                    <SwapVerticalIcon />
                </button>
            </div>
            {isBreak &&
            <div className={'to-amount'}>
                <Counter amount={mkrToBkr / 10 ** bkrDecimals} decimals={0} postfix=' BKR' noColor timeout={25} ignoreZeroLimit={true}/>
            </div>
            }
            {!isBreak &&
            <div className={'to-amount'}>
                <Counter amount={bkrToMkr / 10 ** mkrDecimals} decimals={4} postfix=' MKR' noColor timeout={25} ignoreZeroLimit={true}/>
            </div>
            }
            {(isBkrAmountInsufficient || isMkrAmountInsufficient) &&
            <div className='insufficient-amount'>
                <AlertOutlineIcon />
                <div>
                    insufficient {isBkrAmountInsufficient ? 'BKR' : 'MKR'} balance
                </div>
            </div>
            }
            <div className={`make-transaction ${isDisabled ? 'disabled' : ''}`}>
                {(isMkrUnlocked || !isBreak) && !isBkrAmountInsufficient && !isMkrAmountInsufficient &&
                <button
                    onClick={isBreak ? swapMkrToBkrTransaction : swapBkrToMkrTransaction}
                    disabled={isDisabled}
                >
                    {isBreak ? <HammerIcon/> : <BandageIcon/>}
                    {isBreak ? 'break maker' : 'make breaker'}
                </button>
                }
                {!isMkrUnlocked && isBreak && !isMkrAmountInsufficient &&
                <button
                    disabled={!!isPosting}
                    onClick={unlockMkrTransaction}
                >
                    <LockOpenIcon />Unlock MKR
                </button>
                }
            </div>
            <HammerIcon className={'big-hammer'}/>
        </div>
    )
})

export default Swap