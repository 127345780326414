import React from "react";
import Transaction from "./Transaction";
import './pending-transaction.scss'
import LoaderCircle from "../util/LoaderCircle";

const PendingTransaction = ({hash}) => {
    return (
        <div className={'pending-transaction'}>
            <LoaderCircle />
            <h2>
                submitting transaction
            </h2>
            <Transaction hash={hash}/>
        </div>
    )
}

export default PendingTransaction