import {configureStore} from '@reduxjs/toolkit';
import modalDuck, {NAME as modal} from './modalDuck';
import transactionDuck, {NAME as transaction} from './transactionDuck';

export default configureStore({
  reducer: {
    [modal]: modalDuck,
    [transaction]: transactionDuck,
  },
})
