import {createSlice} from '@reduxjs/toolkit';
import {modalRef} from "../components/modal/Modal";
import {SEC} from "../util/formatting/dates.js";

export const NAME = 'modal'

const getUpdateFn = () => {
    const { update } = modalRef?.current || { update: () => console.error('No modal ref...') }
    return update
}

export const openModal = content => dispatch => {
    const update = getUpdateFn()
    update(content)
    dispatch(activate())
}

export const closeModal = () => (dispatch, getState) => {
    dispatch(deactivate())
    setTimeout(()=>{
        const update = getUpdateFn()
        !getIsActive(getState()) && update(null)
    }, 1.5 * SEC)
}

const initialState = {
    isActive: false,
}

export const {actions, reducer} = createSlice({
    name: NAME,
    initialState,
    reducers: {
        activate: state => {
            state.isActive = true
        },
        deactivate: state => {
            state.isActive = false
        },
    },
})

export const { activate, deactivate } = actions

const getState = state => state[NAME]

export const getIsActive = state => getState(state).isActive


export default reducer
