import {createSlice} from '@reduxjs/toolkit';

export const NAME = 'transaction'

const initialState = {
    isPosting: false,
    isSuccess: false,
    hash: undefined,
    error: undefined,
}

export const {actions, reducer} = createSlice({
    name: NAME,
    initialState,
    reducers: {
        postTransaction: (state, { payload: hash }) => {
            state.hash = hash
            state.isPosting = true
            state.isSuccess = false
        },
        transactionSuccess: (state, { payload: hash }) => {
            state.hash = hash
            state.isPosting = false
            state.isSuccess = true
        },
        setError: (state, { payload: error }) => {
            state.error = error
        },
    },
})

export const {
    postTransaction,
    transactionSuccess,
    setError,
} = actions

const getState = state => state[NAME]

export const getIsPosting = state => getState(state).isPosting
export const getIsSuccess = state => getState(state).isSuccess
export const getError = state => getState(state).error
export const getHash = state => getState(state).hash


export default reducer
